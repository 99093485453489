// == More
// -------------------------------

.more__link {
	color: $orange;
	&:after {
		content: ' →';
	}
	&:hover {
		text-decoration: none;
	}
}
