// == Container
// -------------------------------

%container {
	width: 1000px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 20px;
	padding-left: 20px;
}
