// == Features
// -------------------------------

.features {
	@include reset-list;

	line-height: 1.33;
}

.features__item {
	margin-left: 15px;
	&:nth-child(n+2) {
		margin-top: 20px;
	}
	&:before {
		display: block;
		float: left;

		width: 5px;
		height: 5px;
		margin: 6px 0 0 -15px;

		content: '';

		border-radius: 50%;
		background: $orange;
	}
	&_disable {
		color: #7f7f7f;
		&:before {
			background: #7f7f7f;
		}
	}
}
