// == Parsley
// -------------------------------

.parsley-errors-list {
	@include reset-list;

	font-size: 12px;
	font-style: italic;
	line-height: 20px;

	position: absolute;
	right: 0;
	bottom: 100%;

	color: #d3f2ff;
}
