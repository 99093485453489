// == Content
// -------------------------------

.content {
	@extend %container;

	display: flex;

	margin-top: 60px;
	ul {
		@include reset-list;

		margin: 0 0 1.47em;
		li {
			margin-left: 25px;
			&:nth-child(n+2) {
				margin-top: 4px;
			}
			&:before {
				display: block;
				float: left;

				width: 5px;
				height: 5px;
				margin: 8px 0 0 -15px;

				content: '';

				border-radius: 50%;
				background: $orange;
			}
		}
	}
}

.content__body {
	@include last-margin;

	width: 720px;
}

.content__sidebar {
	width: 240px;
	padding: 0 40px;
}

.content__accent {
	display: block;

	max-width: 100%;
	height: auto;
	margin: 0 auto 40px;
}
