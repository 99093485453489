// == Benefits
// -------------------------------

.benefits {
	margin-top: 60px;
	padding-top: 60px;
	padding-bottom: 60px;

	background: #f4f4f4;
}

.benefits__inner {
	@extend %container;
}

.benefits__list {
	display: flex;

	flex-wrap: wrap;
	&_three {
		margin-right: -30px;
		margin-left: -30px;
	}
}

.benefits__item {
	padding: 20px 18px 17px;

	border-bottom: 3px solid #b0d4e5;
	background: #c1e4f3;
	.benefits__list_three & {
		width: calc(33.3333% - 60px);
		margin-right: 30px;
		margin-left: 30px;
		&:nth-child(n+4) {
			margin-top: 40px;
		}
	}
	.benefits__list_four & {
		width: calc(25% - 40px);
		margin-right: 20px;
		margin-left: 20px;
	}
}

.benefits__hero {
	display: block;

	margin: 0 auto 20px;
}

.benefits__text {
	line-height: 1.33;

	text-align: center;
}
