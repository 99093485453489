// == Special
// -------------------------------

.special {
	padding-top: 60px;
	padding-bottom: 60px;

	background-color: $dark-blue;
	background-image: url(../i/special.jpg);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: auto 100%;
}

.special__form {
	@extend %container;

	color: $yellow;
}

.special__row {
	display: flex;
}

.special__group {
	position: relative;

	width: 240px;
	margin-right: 20px;
}

.special__btn {
	width: 180px;
}
