// == Arrow
// -------------------------------

.arrow {
	position: absolute;
	z-index: 1;

	width: 20px;
	height: 40px;

	cursor: pointer;
	&:before,
	&:after {
		@extend %sprite;

		position: absolute;
		top: 0;
		left: 0;

		display: block;

		width: 20px;
		height: 40px;

		content: '';
		transition: opacity $t;
	}
	&:after {
		opacity: 0;
	}
	&_type_prev {
		&:before {
			background-position: 0 -71px;
		}
		&:after {
			background-position: -20px -71px;
		}
	}
	&_type_next {
		&:before {
			background-position: -40px -71px;
		}
		&:after {
			background-position: -60px -71px;
		}
	}
	&:hover {
		&:before {
			opacity: 0;
		}
		&:after {
			opacity: 1;
		}
	}
}
