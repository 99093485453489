// == Contacts
// -------------------------------

.contacts {
	font-size: 14px;
	line-height: 22px;

	padding-top: 60px;
	padding-bottom: 60px;

	color: #666;
	background: #f4f4f4;
}

.contacts__columns {
	display: flex;

	width: 1000px;
	margin-right: auto;
	margin-left: auto;
}

.contacts__column {
	width: 33.3333%;
	padding: 0 20px;
}
