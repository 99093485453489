// == Clients
// -------------------------------

.clients {
	@extend %container;

	margin-top: 60px;
	margin-bottom: 60px;
}

.clients__slider-holder {
	position: relative;

	padding: 0 60px;
}

.clients__item {
	display: flex;

	height: 100px;
	padding: 9px;

	transition: border-color $t;
	text-decoration: none;

	border: 1px solid transparent;

	align-items: center;
	justify-content: center;
	&:hover {
		border-color: #f0f0f0;
	}
}

.clients__image {
	display: block;

	max-width: 100%;
	height: auto;
}

.clients__prev {
	top: calc(50% - 20px);
	left: 20px;
}

.clients__next {
	top: calc(50% - 20px);
	right: 20px;
}
