// == News
// -------------------------------

.news {
	padding-top: 60px;
	padding-bottom: 60px;

	background-color: #f4f4f4;
	background-image: url(../i/news.jpg);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: auto 100%;
}

.news__columns {
	@extend %container;

	display: flex;
}

.news__column {
	@include last-margin;

	width: 50%;
	padding: 0 20px;
}

.news__item {
	margin-bottom: 40px;
}

.news__date {
	font-size: 15px;
	line-height: 1;

	display: table;

	margin-bottom: 20px;
	padding: 4px 5px 1px;

	background: $yellow;
}
