// == Reviews
// -------------------------------

.reviews {
	@extend %container;

	position: relative;

	margin-top: 60px;
	margin-bottom: 60px;
}

.reviews__slider {
	position: relative;
}

.reviews__item {
	display: flex;

	padding: 0 80px;

	align-items: flex-start;
	justify-content: space-between;
}

.reviews__author {
	width: 160px;
}

.reviews__logo {
	display: flex;

	width: 160px;
	height: 160px;
	margin: 0 0 20px;
	padding: 9px;

	border: 1px solid #f0f0f1;

	align-items: center;
	justify-content: center;
}

.reviews__image {
	display: block;

	max-width: 100%;
	height: auto;
}

.reviews__author-name {
	text-align: center;

	color: #666;
}

.reviews__body {
	position: relative;

	width: 580px;
	padding: 35px 58px;

	border: 2px solid #e4e4e4;
	background: white;
	&:before {
		@include triangle(20px 20px, #e4e4e4, up-right);

		position: absolute;
		top: 18px;
		left: -22px;

		content: '';
	}
	&:after {
		@include triangle(20px 20px, white, up-right);

		position: absolute;
		top: 20px;
		left: -17px;

		content: '';
	}
}

.reviews__text {
	@include last-margin;

	position: relative;

	text-align: justify;
	&:before,
	&:after {
		@extend %sprite;

		position: absolute;

		width: 22px;
		height: 24px;

		content: '';
	}
	&:before {
		top: -18px;
		left: -40px;

		background-position: 0 -47px;
	}
	&:after {
		right: -40px;
		bottom: -18px;

		background-position: -22px -47px;
	}
}

.reviews__prev {
	top: calc(50% - 20px);
	left: 20px;
}

.reviews__next {
	top: calc(50% - 20px);
	right: 20px;
}

.reviews__pagination {
	position: absolute;
	top: 10px;
	right: 40px;

	display: flex;
}

.reviews__bullet {
	display: block;

	width: 10px;
	height: 10px;
	margin-left: 10px;

	cursor: pointer;

	border: 2px solid #c1e4f3;
	border-radius: 50%;
	background: #c1e4f3;
	&_active {
		border-color: #e4e4e6;
		background: white;
	}
}
