// == Services
// -------------------------------

.services {
	@extend %container;

	display: flex;

	margin-top: 60px;

	justify-content: space-between;
}

.services__item {
	display: flex;
	flex-direction: column;

	width: 280px;
	padding: 40px 20px;

	color: white;
	background-color: $dark-blue;
	background-repeat: no-repeat;
	background-size: cover;

	justify-content: space-between;
	&_type_complex {
		background-image: url(../i/service-complex.jpg);
	}
	&_type_banner {
		background-image: url(../i/service-banner.jpg);
	}
	&_type_other {
		background-image: url(../i/service-other.jpg);
	}
}

.services__caption {
	font-family: $opensans;
	font-size: 24px;
	font-weight: bold;
	line-height: 26px;

	text-align: center;

	color: $yellow;
}

.services__text {
	font-size: 16px;
	line-height: 1.375;

	margin-top: 30px;

	text-align: center;
}

.services__footer {
	margin-top: 40px;

	text-align: center;
}
