// == Feedback
// -------------------------------

.feedback {
	padding-top: 40px;
	padding-bottom: 40px;

	background: #c1e4f3;
}

.feedback__inner {
	@extend %container;

	display: flex;

	align-items: center;
	justify-content: center;
}

.feedback__caption {
	font-family: $opensans;
	font-size: 25px;
	font-weight: bold;
	line-height: 28px;

	max-width: calc(100% - 204px);
}

.feedback__btn {
	width: 184px;
	margin-left: 20px;

	align-self: flex-start;
}
