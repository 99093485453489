// == Telephones
// -------------------------------

.tels {
	float: left;

	margin-bottom: -18px;
}

.tels__tabs {
	@include reset-list;

	font-size: 13px;
	line-height: 1;

	display: flex;

	margin: 0 -5px 8px;
}

.tels__tab {
	margin: 0 5px;

	outline: none;
}

.tels__link {
	text-decoration: none;

	border-bottom: 1px dashed;
	outline: none;
	.ui-tabs-active & {
		color: #444;
		border-bottom: none;
	}
}

.tels__panel {
	font-size: 18px;
	font-weight: bold;

	color: #444;
}

.ui-tabs-hide {
	display: none;
}

.tels__email {
	font-size: 13px;
	line-height: 1;

	margin-top: 5px;
}
