// == Promo
// -------------------------------

.promo {
	position: relative;
}

.promo__item {
	position: relative;

	display: block;

	text-decoration: none;
}

.promo__hero {
	display: block;

	width: 100%;
	height: auto;
}

.promo__text {
	font-size: 16px;
	font-weight: bold;
	line-height: 1.25;

	position: absolute;
	bottom: 0;
	left: 0;

	width: 100%;
	padding: 20px 120px 20px 20px;

	color: black;
	background: rgba(255,255,255,.75);
	text-shadow: 1px 1px 0 white;
}

.promo__pagination {
	position: absolute;
	z-index: 1;
	right: 20px;
	bottom: 23px;
}

.promo__bullet {
	float: left;

	width: 14px;
	height: 14px;
	margin-left: 10px;

	cursor: pointer;

	border: 4px solid $dark-blue;
	border-radius: 50%;
	background: $dark-blue;
	&_active {
		background: white;
	}
}
