// == Offer
// -------------------------------

.offer {
	padding-top: 60px;
	padding-bottom: 60px;

	color: white;
	background: $dark-blue;
}

.offer__form {
	@extend %container;
}

.offer__columns {
	display: flex;

	padding: 0 20px;
}

.offer__column {
	@include last-margin;

	margin: 0 10px;
	&:nth-child(1) {
		width: 300px;
	}
	&:nth-child(2) {
		width: 380px;
	}
	&:nth-child(3) {
		width: 180px;
	}
}

.offer__group {
	position: relative;

	margin-bottom: 20px;
}
