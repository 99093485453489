// == Crumbs
// -------------------------------

.crumbs {
	font-size: 12px;
	line-height: 20px;

	color: #777;
	border-bottom: 2px solid #e4e4e4;
	background: #f7f7f7;
}

.crumbs__list {
	display: flex;

	width: 1000px;
	margin-right: auto;
	margin-left: auto;
	padding: 10px 40px 8px;

	flex-wrap: wrap;
}

.crumbs__item {
	&:nth-last-child(n+2):after {
		margin: 0 .25em;

		content: '/';
	}
}
