// == Form
// -------------------------------

// Form layout
.form {
	@include last-margin;
}

.form__group {
	position: relative;

	margin-bottom: 40px;
}

.form__columns {
	display: flex;

	margin: 0 -20px 40px;
}

.form__column {
	@include last-margin;

	width: calc(50% - 40px);
	margin: 0 20px;
}

.form__footer {
	text-align: center;
}

// Input & textarea
.input,
.textarea {
	font-size: 15px;
	line-height: 20px;

	display: block;

	width: 100%;
	padding: 10px 18px 6px;

	color: #333;
	border: 2px solid white;
	outline: none;
	background: white;
	&::placeholder {
		color: #333;
	}
	&_size_lg {
		font-size: 18px;

		padding: 20px 18px 16px;
	}
	&.parsley-error {
		border-color: #ff3600;
	}
}

.textarea {
	&_type_offer {
		height: 160px;
	}
}

// Select
.select {
	font-size: 18px;
	line-height: 20px;

	display: block;

	width: 100%;
	height: 60px;
	padding: 20px 58px 16px 18px;

	cursor: pointer;
	white-space: nowrap;

	color: #333;
	border: 2px solid white;
	border-radius: 0;
	outline: none;
	background: white url(../i/select.png) no-repeat 100% 50%;

	appearance: none;
	&::-ms-expand {
		display: none;
	}
	&.parsley-error {
		border-color: #ff3600;
	}
}

// Button
.btn {
	font-size: 16px;
	line-height: 20px;

	display: inline-block;

	padding: 10px 30px;

	transition: background $t;
	text-align: center;
	text-decoration: none;

	color: #333;
	border: none;
	border-radius: 20px;
	outline: none;
	background: $yellow;
	&:hover {
		color: #333;
		background: #ffaa01;
	}
	&_type_more:after {
		margin-left: .25em;

		content: '→';
	}
	&_type_accent {
		display: block;

		width: 100%;
		padding-right: 0;
		padding-left: 0;
	}
	&_type_order {
		width: 200px;

		text-transform: uppercase;
	}
	&_wide {
		display: block;

		width: 100%;
	}
	&_size_lg {
		font-size: 18px;

		padding: 20px 50px;

		border-radius: 30px;
	}
}
