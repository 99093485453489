// == Popup
// -------------------------------

.popup {
	position: relative;

	margin-right: auto;
	margin-left: auto;
	padding: 60px;

	transition: all $t;
	transform: scale(.75);

	opacity: 0;
	color: white;
	background: $dark-blue;
	&_size_md {
		width: 480px;
	}
	&_size_lg {
		width: 840px;
	}
	.mfp-ready & {
		transform: scale(1);

		opacity: 1;
	}
	.mfp-removing & {
		transform: scale(.75);

		opacity: 0;
	}
}

.popup__close {
	@include hide-text;
	@extend %sprite;

	position: absolute;
	top: 20px;
	right: 20px;

	width: 20px;
	height: 20px;
	padding: 0;

	border: none;
	outline: none;
	background-color: transparent;
	background-position: 0 -111px;
}

.popup__caption {
	font-family: $opensans;
	font-size: 25px;
	font-weight: bold;
	line-height: 28px;

	margin-bottom: 32px;

	text-align: center;

	color: $yellow;
}
