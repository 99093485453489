// == Order
// -------------------------------

.order {
	padding: 40px 20px 20px;

	color: white;
	background: $dark-blue;
}

.order__caption {
	font-family: $opensans;
	font-size: 24px;
	font-weight: bold;
	line-height: 26px;

	margin-bottom: 30px;

	text-align: center;
}

.order__group {
	position: relative;

	margin-bottom: 40px;
}

.order__footer {
	text-align: center;
}
