// == Footer
// -------------------------------

.footer {
	padding-top: 40px;
	padding-bottom: 40px;

	background: #444450;
}

.footer__siteinfo {
	@extend %container;

	font-size: 14px;
	line-height: 20px;

	text-align: center;

	color: #ccc;
}

.footer__link {
	color: #ccc;
	&:hover {
		color: white;
	}
}
