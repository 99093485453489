// == Intro
// -------------------------------

.intro {
	@extend %container;

	display: flex;

	margin-top: 60px;
}

.intro__promo {
	width: 660px;
}

.intro__order {
	width: 300px;
}
