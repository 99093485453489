// == Callorder
// -------------------------------

.callorder {
	font-size: 16px;
	line-height: 1.25;

	float: right;

	padding: 10px 30px;

	transition: background $t;
	text-decoration: none;

	color: white;
	border: none;
	border-radius: 20px;
	outline: none;
	background: $orange;
	&:hover {
		color: white;
		background: lighten($orange, 5%);
	}
}
