// == Offers
// -------------------------------

.offers {
	@extend %container;

	margin-top: 60px;
}

.offers__list {
	display: flex;

	justify-content: space-between;
}

.offers__item {
	display: flex;
	flex-direction: column;

	width: 280px;

	justify-content: space-between;
}

.offers__body {
	width: 100%;
}

.offers__caption {
	font-family: $opensans;
	font-size: 14px;
	font-weight: bold;
	line-height: 18px;

	display: block;

	padding: 12px 20px;

	text-align: center;

	background: #c1e4f3;
}

.offers__text {
	line-height: 1.33;

	margin-top: 40px;

	text-align: justify;
}

.offers__footer {
	margin-top: 40px;
}

.offers__price {
	font-family: $opensans;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;

	padding: 17px 20px;

	text-align: center;

	border-top: 1px solid #e4e4e4;
	border-bottom: 1px solid #e4e4e4;
	background: #f4f4f4;
}

.offers__btn-holder {
	margin-top: 40px;

	text-align: center;
}
