@charset 'UTF-8';

// Fonts
$calibri: Calibri, sans-serif;
$opensans: 'Open Sans', sans-serif;

// Colors
$blue: #0070bf;
$dark-blue: #011b53;
$orange: #ff7200;
$yellow: #ffd012;

// Transition
$t: .15s ease;
