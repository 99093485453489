// == Nav
// -------------------------------

.nav {
	font-size: 16px;
	line-height: 1.25;

	background: #ededed;
}

.nav__list {
	@include reset-list;
	@extend %container;

	display: flex;

	margin-right: auto;
	margin-left: auto;
	padding-top: 20px;
	padding-bottom: 20px;

	justify-content: center;
}

.nav__item {
	margin: 0 25px;
	&_active {
		color: #444;
	}
}
