// == Advantages
// -------------------------------

.advantages {
	margin-top: 60px;
	padding-top: 58px;
	padding-bottom: 58px;

	border-top: 2px solid #e4e4e4;
	border-bottom: 2px solid #e4e4e4;
	background: #f4f4f4;
}

.advantages__inner {
	@extend %container;
}

.advantages__list {
	@include reset-list;

	display: flex;

	margin-right: -20px;
	margin-left: -20px;

	counter-reset: adv;

	flex-wrap: wrap;
}

.advantages__item {
	display: flex;

	width: calc(50% - 40px);
	min-height: 60px;
	margin-right: 20px;
	margin-left: 20px;
	padding: 10px 20px;

	counter-increment: adv;

	background: $dark-blue;

	align-items: center;
	justify-content: space-between;
	&:nth-child(n+3) {
		margin-top: 20px;
	}
	&:before {
		font-size: 15px;
		font-weight: bold;
		line-height: 17px;

		display: block;

		width: 20px;
		height: 20px;
		padding-top: 3px;

		content: counter(adv);
		text-align: center;

		color: $dark-blue;
		border-radius: 50%;
		background: $yellow;
	}
}

.advantages__text {
	font-size: 15px;
	font-weight: bold;
	line-height: 20px;

	width: calc(100% - 40px);

	color: white;
}
