// == Success
// -------------------------------

.success {
	width: 480px;
	margin-right: auto;
	margin-left: auto;
	padding: 60px;

	transition: all $t;
	transform: scale(.75);
	text-align: center;

	opacity: 0;
	background: #d9d9d9;
	.mfp-ready & {
		transform: scale(1);

		opacity: 1;
	}
	.mfp-removing & {
		transform: scale(.75);

		opacity: 0;
	}
}

.success__caption {
	font-family: $opensans;
	font-size: 25px;
	font-weight: bold;
	line-height: 28px;

	margin-bottom: 32px;
}

.success__text {
	font-size: 18px;
	line-height: 22px;
}
