// == Products
// -------------------------------

.products {
	padding-top: 2px;

	border-bottom: 2px solid #e4e4e4;
}

.products__list {
	@extend %container;
}

.products__item {
	padding: 58px 0;
	&:nth-child(n+2) {
		border-top: 2px solid #c1e4f3;
	}
}

.products__caption {
	font-family: $opensans;
	font-size: 22px;
	font-weight: bold;
	line-height: 28px;

	margin-bottom: 32px;
	&:before {
		@extend %sprite;

		display: inline-block;

		width: 17px;
		height: 16px;
		margin-right: 10px;

		content: '';

		background-position: 0 -131px;
	}
}

.products__description {
	@include last-margin;
}

.products__info {
	display: flex;

	margin-top: 40px;

	justify-content: space-between;
}

.products__details {
	width: 620px;
}

.products__view {
	width: 280px;
}

.products__image {
	display: block;

	max-width: 100%;
	height: auto;
	margin: 0 auto;
}

.products__btn-holder {
	margin-top: 40px;

	text-align: center;
}
