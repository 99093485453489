// == Tariffs
// -------------------------------

.tariffs {
	@extend %container;

	margin-top: 60px;
}

.tariffs__list {
	display: flex;

	justify-content: space-between;
}

.tariffs__item {
	display: flex;
	flex-direction: column;

	width: 280px;

	justify-content: space-between;
}

.tariffs__body {
	width: 100%;
}

.tariffs__name {
	font-family: $opensans;
	font-size: 20px;
	font-weight: bold;
	line-height: 30px;

	margin-bottom: 40px;
	padding: 15px 20px;

	text-align: center;

	background: #c1e4f3;
	&:before {
		font-size: 14px;
		line-height: 20px;

		display: inline-block;

		width: 20px;
		height: 20px;
		margin: 4px 12px 0 0;

		text-align: center;
		vertical-align: top;
		text-transform: uppercase;

		color: white;
		border-radius: 50%;
	}
	&_type_standart:before {
		content: 's';

		background: #011b53;
	}
	&_type_business:before {
		content: 'b';

		background: #0ca300;
	}
	&_type_maximum:before {
		content: 'm';

		background: #e92600;
	}
}

.tariffs__footer {
	width: 100%;
	margin-top: 60px;
}

.tariffs__price {
	font-family: $opensans;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;

	padding: 15px 20px;

	text-align: center;

	border-top: 1px solid #e4e4e4;
	border-bottom: 1px solid #e4e4e4;
	background: #f4f4f4;
}

.tariffs__note {
	font-size: 13px;
	font-weight: bold;
	font-style: italic;
	line-height: 15px;

	padding: 5px 20px;

	text-align: center;

	color: #666;
	background: #def1f9;
}

.tariffs__btn-holder {
	margin-top: 40px;

	text-align: center;
}
