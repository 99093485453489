// == Header
// -------------------------------

.header {
	@include clearfix;
	@extend %container;

	padding-top: 60px;
	padding-bottom: 60px;
}

.header__logo {
	@include hide-text;
	@extend %sprite;

	float: left;

	width: 266px;
	height: 47px;
	margin: -2px 32px -5px 0;
}

.header__slogan {
	font-size: 14px;
	line-height: 17px;

	float: left;

	width: 240px;
	margin: 6px 60px 0 0;

	color: #777;
}
