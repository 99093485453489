// == Base
// -------------------------------

*,
:before,
:after {
	box-sizing: border-box;
}

html {
	background: white;
}

body {
	font-family: $calibri;
	font-size: 15px;
	line-height: 1.47;

	min-width: 1000px;

	color: #333;
}

a {
	transition: color $t;

	color: $blue;
	&:hover {
		color: $orange;
	}
}

h1 {
	font-family: $opensans;
	font-size: 25px;
	font-weight: normal;
	line-height: 1.2;

	margin: 0 0 1.2em;
}

h2 {
	font-family: $opensans;
	font-size: 25px;
	font-weight: bold;
	line-height: 28px;

	margin: 0 0 32px;

	text-align: center;
}

p {
	margin: 0 0 1.47em;
}

table {
	width: 100%;
}

th {
	font-family: $opensans;
	font-size: 14px;
	font-weight: bold;
	line-height: 20px;

	padding: 20px;

	text-align: center;

	background: #c1e4f3;
}

td {
	line-height: 1.33;

	padding: 19px 20px;

	border-bottom: 1px solid #e4e4e4;
	tr:nth-child(2n+1) & {
		background: #f4f4f4;
	}
	&:nth-child(n+2) {
		text-align: center;
	}
}
